<template>
  <router-view></router-view>
</template>

<script>
import { uiHelper, locale } from '@/util'
export default {
  name: 'TableLayout',
  metaInfo: {
    title: `${locale.getMessage('meta.default_title')}`,
    titleTemplate: `%s | ${uiHelper.getHost()}`,
    meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.default_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.default_keyword')}` }]
  },
  methods: {
    openLoginDialog() {
      this.$parent.openLoginDialog()
    }
  }
}
</script>

<style>
.game_icon_sizing {
  border-radius: 15px !important;
}
</style>